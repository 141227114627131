import classNames from "classnames";
import React from "react";
import { setTime } from "../store/features/booking";
import Button from "./Button";
import { useAppDispatch } from "../store";

interface TimeOptionProps {
  disabled: boolean;
  time: string;
  selected?: boolean;
}

const TimeOption: React.FC<TimeOptionProps> = ({
  disabled,
  time,
  selected,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      className={classNames("timeslot", {
        "timeslot-selected": selected,
      })}
      disabled={disabled}
      onClick={() => dispatch(setTime(time))}
    >
      {time}
    </Button>
  );
};

export default TimeOption;
