import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import * as yup from "yup";
import api from "../../api";
import { viewPages } from "../../constants/view";
import useGoogleAnalytics from "../../hooks/useAnalytics";
import { useAppDispatch, useAppSelector } from "../../store";
import { setUserData } from "../../store/features/booking";
import { setLoading, setPage } from "../../store/features/view";
import Button from "../Button";
import Label from "../Label";

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers.")
    .required("Name is required."),
  surname: yup
    .string()
    .matches(/^([^0-9]*)$/, "Surname should not contain numbers.")
    .required("Surname is required."),
  email: yup
    .string()
    .email("Email format is not valid.")
    .required("Email is required."),
  phone: yup
    .string()
    .min(8, "Phone number is too short.")
    .max(24, "Phone number is too long.")
    .required("Phone is required."),
});

const constructDate = (date: string, hour: number, minute: number) => {
  const dayjsDate = dayjs(date).hour(hour).minute(minute);

  return dayjs(dayjsDate).format("YYYY-MM-DDTHH:mm:ssZ");
};

const buildMeetingPayload = (consumer: any, date: any, time: any, stylistId: any, site: any, tenantId: string) => {
  const payload: any = {
    title: `Meeting ( ${consumer.email} )`,
    status: "pending",
    consumer: {
      email: consumer.email,
      firstname: consumer.name,
      lastname: consumer.surname,
      phone: consumer.phone,
    },
    notes: consumer.notes,
    stylistId,
    site,
    tenantId
  };

  const [hour, minute] = time.split(":");

  payload.startDate = constructDate(date, parseInt(hour), parseInt(minute));
  payload.finishDate = constructDate(
    date,
    parseInt(hour) + 1,
    parseInt(minute)
  );

  return payload;
};

const WizardInfoStep: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const date = useAppSelector((state) => state.booking.date);
  const time = useAppSelector((state) => state.booking.time);
  const { stylistId, site, tenantId } = useAppSelector((state) => state.view);
  const [error, setError] = useState(false);
  const { event } = useGoogleAnalytics();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      notes: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setError(false);
      dispatch(setLoading(true));
      event({
        category: "booking_submit",
        action: "button_click",
      })
      const payload = buildMeetingPayload(data, date, time, stylistId, site, tenantId);
      await api.MeetingService.createMeeting(payload);

      dispatch(setUserData(data));
      dispatch(setPage(viewPages.SUCCESS));
      event({
        category: "booking_completed",
        action: "booking_completed",
      });
    } catch (e) {
      setError(true);
      event({
        category: "booking_submition_failed",
        action: "booking_failed",
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <form
      className="info-step"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group">
        <Label label={t("INFO.NAME")} htmlFor={"Name"} required />
        <input
          id="Name"
          type="text"
          placeholder={t("INFO.NAME")}
          className={classNames("form-control", { "error": errors.name })}
          {...register("name")}
          required
        />
        {/* {errors.name && <span className="ml-2 text-red-500">{errors?.name?.message}</span>} */}
      </div>
      <div className="form-group ">
        <Label label={t("INFO.SURNAME")} htmlFor={"Surname"} required />
        <input
          id="Surname"
          type="text"
          placeholder={t("INFO.SURNAME")}
          className={classNames("form-control", { "error": errors.surname })}
          {...register("surname")}
          required
        />
        {/* {errors.surname && <span className="ml-2 text-red-500">{errors?.surname?.message}</span>} */}
      </div>
      <div className="form-group">
        <Label label="Email" htmlFor={"Email"} required />
        <input
          id="Email"
          type="email"
          placeholder="Email"
          className={classNames("form-control", { "error": errors.email })}
          {...register("email")}
          required
        />
        {/* {!!errors.email && <span className="ml-2 text-red-500">{errors?.email?.message}</span>} */}
      </div>
      {/* TODO: error wont display if its just touched, validate function runs on change */}
      <Controller
        name="phone"
        control={control}
        rules={{
          validate: (value) => isValidPhoneNumber(value as any),
        }}
        render={({ field: { onChange, value } }) => (
          <div className="form-group ">
            <Label label={t("INFO.PHONE")} htmlFor={"phone"} required />
            <PhoneInput
              placeholder={t("INFO.PHONE")}
              className={classNames("form-control", { "error": errors.phone })}
              value={value as any}
              defaultCountry="IT"
              onChange={onChange as any}
              useNationalFormatForDefaultCountryValue={false}
              id="phone"
            />
            {/* {errors.phone && <span className="ml-2 text-red-500">{errors?.phone?.message}</span>} */}
          </div>
        )}
      />

      <div className="form-group">
        <Label label={t("INFO.WHAT_ARE_YOU_LOOKING")} htmlFor="notes" />
        <textarea
          id="notes"
          placeholder={t("INFO.WRITE_HERE")}
          className="form-control max-h-28"
          {...register("notes")}
        />
      </div>
      <div className="alert-row">
        {error ?
          <div className="alert error">
            <p>
              {t("ERROR.MESSAGE")}
            </p>
          </div> : ""}
      </div>
      <Button
        type="submit"
        full
        disabled={!isValid || !isDirty}
      >
        {t("BOOK_NOW")}
      </Button>
    </form>
  );
};
export default WizardInfoStep;
