import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { viewPages } from '../constants/view';
import useGoogleAnalytics from '../hooks/useAnalytics';
import { useAppDispatch, useAppSelector } from '../store';
import { setPage } from '../store/features/view';
import Button from './Button';
import ImageCarousel from './ImageCarousel';
import mp from "../assets/images/mp.jpg";

const CallToAction: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { event } = useGoogleAnalytics();
  const { page, toggled } = useAppSelector((state) => state.view);

  const bookNow = () => {
    dispatch(setPage(viewPages.BOOKING));
    event({
      category: "normal_booking_start",
      action: "button_click",
    })
  };

  const goToLive = () => {
    dispatch(setPage(viewPages.LIVE));
    event({
      category: "instant_booking_start",
      action: "button_click",
    })
  };

  return (
    <div className={classNames("call-to-action mr-4", className)}>
      {page === viewPages.HOME &&
        <React.Fragment>
          <div className="flex-vertical-center">
            <h2 className='text-title'>{t("HELP")}</h2>


          </div>
          <h3 className={classNames("text-subtitle", {})}>{t("BOOK_APPOINTMENT")}</h3>
        </React.Fragment>
      }
      {
        toggled && <div>
          <p>{t("HOME_DESCRIPTION")}</p>
          {/* <ImageCarousel /> */}
          <img src={mp} alt="" />
          <div className='text-center'>
            <Button className="secondary" full style={{ background: "#67d1cf", height: "auto", textTransform: "unset" }} onClick={goToLive}>{t("INSTANT_CALL.ENTER")}</Button>
            <div className='or pt-2'>{t("OR")}</div>
            <Button onClick={bookNow} full>{t("BOOK_A_SESSION")}</Button>
          </div>
        </div>
      }
    </div >
  )
}
export default CallToAction;