import classnames from "classnames";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { MutatingDots } from "react-loader-spinner";
import { useResizeDetector } from "react-resize-detector";
import CallToAction from "../components/CallToAction";
import { applicationCopyright } from "../constants/appSettings";
import { viewPages } from "../constants/view";
import useGoogleAnalytics from "../hooks/useAnalytics";
import Booking from "../pages/Booking";
import InstantMeeting from "../pages/InstantMeeting";
import Success from "../pages/Success";
import { useAppDispatch, useAppSelector } from "../store";
import { setPage, setToggled, setView } from "../store/features/view";

const WidgetLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { loading, view, page: activePage, toggled } = useAppSelector((state) => state.view)
  const mqttLoading = useAppSelector((state) => state.mqtt.loading)
  const step = useAppSelector((state) => state.booking.step)
  const { height, ref } = useResizeDetector();
  const { event } = useGoogleAnalytics();
  React.useLayoutEffect(() => {
    const triggerEventResize = () => {
      const { height, width } = ref.current.getBoundingClientRect();

      window.parent.window.postMessage({
        type: "eligo-resize",
        height: height + 2,
        width: width,
      }, "*");
    }
    let viewVal = window.location.href.match(/view=\w+/g) || [];
    if (viewVal && viewVal[0]) {
      dispatch(setView(viewVal[0].replace("view=", "")));
    }
    const timeout = setTimeout(() => {
      triggerEventResize();
    }, 100);
    return () => clearTimeout(timeout);
  }, [dispatch, activePage, toggled, step, ref, containerRef, height,]);

  const toggleHandler = (init?: boolean) => {
    if (init) {
      dispatch(setPage(viewPages.HOME));
    }
    dispatch(setToggled(!toggled));
    window.parent.window.postMessage({
      type: "eligo-toggle-widget",
      value: !toggled
    }, "*");
    event({
      category: !toggled ? "widget_closed" : "widget_reopened",
      action: "button_click"
    })
  };

  return (
    <div
      ref={ref}
      className={classnames("widget-main-window", {
        "cursor-pointer": !toggled,
        active: toggled,
        [view]: view,
        "pb-[40px]": toggled && activePage !== viewPages.HOME,
      })}
      onClick={!toggled ? () => toggleHandler(false) : () => { }}
    >
      <div ref={containerRef}>
        {
          loading ?
            <div className="eligo-loading__backdrop flex-center left-0 top-0 min-h-full min-w-full absolute z-100 bg-eligo-blue-50 bg-opacity-70" onClick={(ev) => ev.stopPropagation()}>
              <MutatingDots wrapperClass="eligo-loading" height="100px" width="100px" />
            </div> : <></>

        }

        {(activePage === viewPages.HOME || !toggled) && <CallToAction />}
        {
          !mqttLoading && <div
            className={classnames("close-button", { "rotate-180": !toggled })}
            onClick={() => toggleHandler(true)}
          >
            <FaAngleDown size={20} />
          </div>
        }
        {toggled && (
          <React.Fragment>
            {activePage === viewPages.BOOKING && <Booking />}
            {activePage === viewPages.LIVE && <InstantMeeting />}
            {activePage === viewPages.SUCCESS && <Success />}
            <div className="copyright">{applicationCopyright}</div>
          </React.Fragment>
        )}
      </div>

    </div>
  );
};
export default WidgetLayout;
